<template>
  <!-- 订单列表页面 -->
  <div class="container">
    <a-row>
      <!-- 顶部信息栏 -->
      <a-row class="right-content-top" :gutter="10">
        <a-col :span="8" class="header-info">
          <div class="bg-info">
            <img src="@/assets/icons/icon_01.png" alt="" />
            <div class="icon-wrapper">
              <h1>{{ topInfoList.orderNum }}</h1>
              <span>订单数量</span>
            </div>
          </div>
        </a-col>
        <a-col :span="8" class="header-info">
          <div class="bg-info">
            <img src="@/assets/icons/icon_02.png" alt="" />
            <div class="icon-wrapper">
              <h1>{{ topInfoList.orderAmount }}</h1>
              <span>订单金额</span>
            </div>
          </div>
        </a-col>
        <a-col :span="8" class="header-info">
          <div class="bg-info">
            <img src="@/assets/icons/icon_04.png" alt="" />
            <div class="icon-wrapper">
              <h1>{{ topInfoList.entryAmount }}</h1>
              <span>入账金额</span>
            </div>
          </div>
        </a-col>
        <!-- <a-col :span="6" class="header-info">
                <div class="bg-info">
                    <img src="@/assets/icons/icon_04.png" alt="">
                    <div  class="icon-wrapper">
                        <h1>{{topInfoList.todayPayAmount}}</h1>
                        <span>支付金额</span>
                    </div>
                </div>
            </a-col> -->
      </a-row>
      <a-col :span="24" class="right">
        <!-- 筛选和表格 -->
        <div class="right-content">
          <!-- 顶部筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 订单号 -->
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-input-search
                      placeholder="请输入订单号"
                      v-model="queryParam.orderNo"
                      @pressEnter="initTableData"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-input-search
                      placeholder="请输入关键词"
                      v-model="queryParam.keyword"
                      @pressEnter="initTableData"
                    />
                  </a-form-item>
                </a-col>
                <!-- 商品名称 -->
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-input-search
                      placeholder="请输入商品名称"
                      v-model="queryParam.goodsName"
                      @pressEnter="initTableData"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-select v-model="queryParam.isStoreOrder" allow-clear placeholder="请选择订单类型">
                      <a-select-option :value="1">商家</a-select-option>
                      <a-select-option :value="2">平台</a-select-option>
                      <a-icon slot="suffixIcon" type="search" />
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 状态筛选 -->
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-select v-model="queryParam.orderStatus" allow-clear placeholder="请选择订单状态">
                      <a-select-option :value="1">待付款</a-select-option>
                      <a-select-option :value="2">已付款</a-select-option>
                      <a-select-option :value="3">已发货</a-select-option>
                      <a-select-option :value="4">已收货</a-select-option>
                      <a-select-option :value="5">已到账</a-select-option>
                      <a-select-option :value="6">已取消</a-select-option>
                      <a-select-option :value="7">退款成功</a-select-option>
                      <a-select-option :value="8">退款中</a-select-option>
                      <a-select-option :value="9">退款失败</a-select-option>
                      <a-icon slot="suffixIcon" type="search" />
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 时间 -->
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <!-- 支付方式 -->
                <a-col :md="6" :sm="24">
                  <a-form-item>
                    <a-select v-model="queryParam.payType" allow-clear placeholder="请选择支付方式">
                      <a-select-option :value="1">支付宝</a-select-option>
                      <a-select-option :value="2">微信</a-select-option>
                      <a-select-option :value="3">余额</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="handleResetSearch">重置</a-button>
                  <a-button type="primary" @click="exportData" style="margin-left: 8px">导出</a-button>
                  <a-button @click="downloadTemplate" style="margin-left: 8px">下载模板</a-button>
                  <a-upload
                    name="file"
                    action=""
                    :showUploadList="false"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    :customRequest="batchDelivery"
                    v-if="+queryParam.isStoreOrder === 2"
                  >
                    <a-button type="primary" style="margin-left: 8px; background-color: #27b148">批量发货</a-button>
                  </a-upload>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 支付方式插槽 -->
            <span slot="payType" slot-scope="text">
              <span>{{ payType[text] }}</span>
            </span>
            <!-- 订单状态插槽 -->
            <span slot="orderStatus" slot-scope="text">
              {{
                text == 1
                  ? '已下单'
                  : text == 2
                    ? '付款成功'
                    : text == 3
                      ? '已发货'
                      : text == 4
                        ? '已收货'
                        : text == 5
                          ? '已到账'
                          : text == 6
                            ? '已取消'
                            : text == 7
                              ? '退款成功'
                              : text == 8
                                ? '退款中'
                                : text == 9
                                  ? '退款失败'
                                  : ''
              }}
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <span v-show="record.orderStatus == 2 && +queryParam.isStoreOrder === 2">
                <a @click="$refs.sendForm.send(record)">发货</a>
                <a-divider type="vertical" />
              </span>

              <span v-show="record.orderStatus >= 3 && record.orderStatus <= 5">
                <a @click="$refs.expressForm.express({ record: record, type: 1 })">发货物流</a>
                <a-divider type="vertical" />
              </span>
              <span v-show="record.checkLogistics == 1">
                <a @click="$refs.expressForm.express({ record: record, type: 2 })">退货物流</a>
                <a-divider type="vertical" />
              </span>
              <a @click="$refs.detailForm.detail(record)">详情</a>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <send-form ref="sendForm" @ok="getList"></send-form>
    <express-form ref="expressForm"></express-form>
    <detail-form ref="detailForm" @ok="getList"></detail-form>
  </div>
</template>

<script>
import { getOrderList, orderTop, downloadExcel, batchDelivery } from '@/api/modular/mallLiving/orderAdmin'

import returnForm from './returnForm.vue'
import sendForm from './sendForm.vue'
import detailForm from './detailForm.vue'
import expressForm from './expressForm.vue'
import { config } from '@/config'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '订单号',
    align: 'center',
    dataIndex: 'orderNo',
    key: 'orderNo',
    width: '200px',
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    width: '100px',
    align: 'center',
    scopedSlots: {
      customRender: 'orderStatus',
    },
  },
  {
    title: '下单时间',
    dataIndex: 'createTime',
    width: '180px',
    align: 'center',
  },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    key: 'payTime',
    width: '180px',
    align: 'center',
  },
  {
    title: '用户昵称',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
    width: '180px',
  },

  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
    width: '180px',
  },

  {
    title: '支付金额',
    dataIndex: 'payPrice',
    key: 'payPrice',
    width: '100px',
    align: 'center',
  },

  {
    title: '支付方式',
    dataIndex: 'payType',
    key: 'payType',
    width: '80px',
    align: 'center',
    scopedSlots: {
      customRender: 'payType',
    },
  },

  {
    title: '操作',
    align: 'center',
    width: '180px',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      columns,
      payType: config.payType,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {
        isExport: 1,
        isStoreOrder: 2,
        orderNo: '',
        keyword: '',
        time: [],
        goodsName: '',
        orderStatus: undefined,
        payType: undefined,
      },
      topInfoList: {
        orderNum: '',
        orderAmout: '',
        entryAmout: '',
      },
    }
  },
  components: {
    returnForm,
    sendForm,
    detailForm,
    expressForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    downloadTemplate() {
      downloadExcel().then((res) => {
        console.log('model', res)
        if (res.data) {
          this.download({
            data: res.data,
            name: '批量发货模板.xlsx',
          })
        }
      })
    },
    download(data) {
      const link = document.createElement('a')
      let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = data.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    batchDelivery(event) {
      console.log('file', file)
      const file = event.file
      if (file) {
        if (
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel'
        ) {
          this.importExport(file)
        } else {
          this.$message.error('附件格式错误，请删除后重新上传！')
        }
      } else {
        this.$message.warning('请上传附件！')
      }
    },
    importExport(file) {
      console.log('file', file)
      const formData = new FormData()
      formData.append('file', file)
      batchDelivery(formData)
        .then((res) => {
          console.log('res', res)
          this.readBlobData(res.data)
            .then((res) => {
              if (res.success) {
                this.$message.success('导入成功')
                this.initTableData()
              } else {
                this.$message.error(res.message || '导入失败')
              }
            })
            .catch((error) => {
              this.$message.error(error.message || '导入失败')
              console.log('error', error)
            })
        })
        .catch((error) => {
          this.$message.error(error.message || '导入失败')
        })
    },
    readBlobData(data) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader() // 创建读取文件对象
        reader.readAsText(data, 'utf-8') // 设置读取的数据以及返回的数据类型为utf-8
        reader.addEventListener('loadend', function () {
          //
          try {
            let res = JSON.parse(reader.result) // 返回的数据
            console.log(res, '返回结果')
            resolve(res)
          } catch (e) {
            reject(e)
          }
        })
      })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    handleResetSearch() {
      this.queryParam = {
        isExport: 1,
        isStoreOrder: 2,
        orderNo: '',
        keyword: '',
        time: [],
        goodsName: '',
        orderStatus: '',
        payType: '',
      }

      this.initTableData()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    // 顶部信息接口
    getTopInfo() {
      orderTop(this.queryParam).then((res) => {
        if (res.success) {
          this.topInfoList = res.data
          console.log('123', this.topInfoList)
        }
      })
    },
    exportData() {
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 2
      this.axios
        .post(`/order/page`, this.queryParam, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '订单记录_' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //时间处理函数
    onChange(date) {
      var date = new Date(date).getTime()
      // var year = date.getFullYear()
      // var month = date.getMonth() + 1
      // var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return date
    },
    getList() {
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 1
      const params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      getOrderList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
      // 获取顶部数据
      this.getTopInfo()
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.right-content-top {
  box-sizing: border-box;
  padding-bottom: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

// 顶部信息栏样式
.header-info {
  .bg-info {
    background: rgba(255, 255, 255, 100);
    padding: 5px;

    img {
      width: 95px;
      height: 107px;
    }

    .icon-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;

      h1 {
        font-size: 23px;
        line-height: 25px;
      }
    }
  }
}
</style>
